<template>
  <div class="body mobile">
    <!-- class: fixed, mobile -->
    <!-- Loading Spinner -->
    <!-- <div class="spinner"><div><div></div></div></div> -->
    <!-- Header -->
    <section class="header notice">
      <div class="flexH width">
        <a class="icon iconButton third" @click.prevent="goBack">
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
      </div>
      <span class="fL nowrap font-bold font-white">數位圖書證</span>
      <div class="flexH width right"></div>
    </section>

    <!-- Main -->
    <section class="main notice">
      <!-- 紀錄 -->
      <div class="records flexV width padding">
        <div class="record mt-2">
          <div class="flexH width relative mb-1">
            <div class="flexV width mr-3 line-height leading-8">
              <div class="fS text-gray-500">雲林縣公共圖書館</div>
              <div
                v-if="currentActive !== undefined && currentActive.length == 0"
              >
                <div class="fL">尚未綁定圖書證</div>
                <div class="fS">-</div>
              </div>
              <div
                v-if="currentActive !== undefined && currentActive.length > 0"
              >
                <div class="fL">{{ currentActive[0].name }}</div>
                <div class="fS">{{ currentActive[0].card_no }}</div>
              </div>
            </div>
            <div class="gap-1">
              <img
                v-if="status === 0"
                src="@/assets/img/binding01.png"
                alt=""
              />
              <img
                v-if="status === 1"
                src="@/assets/img/binding02.png"
                alt=""
              />
              <img
                v-if="status === 2"
                src="@/assets/img/binding03.png"
                alt=""
              />
            </div>
          </div>
          <div class="mb-3">
            <RouterLink to="/digital-card/add" v-if="digitalList.length < 8">
              <button class="button" style="width: 100%">新增數位圖書證</button>
            </RouterLink>
          </div>
          <div class="buttons">
            <div
              v-if="digitalList !== undefined && digitalList.length > 0"
              class="flex"
              style="width: 100%"
            >
              <button
                class="mr-1 button-outline button"
                @click="change()"
                style="width: 100%; white-space: nowrap"
              >
                切換圖書證
              </button>

              <button
                @click="popupObj.open = true"
                class="button"
                style="width: 100%; white-space: nowrap"
              >
                圖書證 QR code
              </button>

              <DigitalCardQrcode
                :open="popupObj.open"
                :title="popupObj.title"
                :bottomTitle="popupObj.buttomTitle"
                :readercode="popupObj.readercode"
                v-if="currentActive !== undefined"
                @popupOpen="setPopupObj"
              ></DigitalCardQrcode>
            </div>
          </div>
        </div>
      </div>
      <div
        class="records flexV width padding"
        v-if="digitalList !== undefined && digitalList.length > 0"
      >
        <div class="record mt-2 flex justify-around">
          <RouterLink
            class="text-center"
            :to="'/digital-card/task?readercode=' + readerCode"
          >
            <img src="@/assets/icon/book01.svg" alt="" />
            <div>圖書任務</div>
          </RouterLink>
          <RouterLink
            class="text-center"
            :to="'/digital-card/borrowing-list?readercode=' + readerCode"
          >
            <img src="@/assets/icon/book02.svg" alt="" />
            <div>借閱清單</div>
          </RouterLink>
          <RouterLink
            class="text-center"
            :to="'/digital-card/reserve?readercode=' + readerCode"
          >
            <img src="@/assets/icon/book03.svg" alt="" />
            <div>線上預約</div>
          </RouterLink>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapState } from "vuex";
import axios from "axios";
import DigitalCardQrcode from "../../components/DigitalCardQrcode.vue";

export default {
  name: "",
  components: {
    DigitalCardQrcode,
  },
  data() {
    return {
      latestReadAt: 0,
      status: 1,
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      digitalList: {},
      currentActive: {},
      popupObj: {
        open: false,
        buttomTitle: "",
        title: "掃描數位圖書證 QR Code",
        readercode: "",
      },
      readerCode: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    console.log(to, from);
    next((vm) => {
      let that = vm;
      if (that.user && that.user.userId) {
        //
      } else {
        // window.location.reload();
        next("/home");
      }
    });
  },
  created() {},
  mounted() {
    this.getDigitalCardNoList();

    // this.onSnapShot();

    //NOTE: Howard's fix: 現在notification直接從store取用，不用再原地去Firestore撈了
    // this.getLatestReadTime()
    // .then(() => {
    //     this.onSnapShot();
    // });

    setTimeout(() => {
      console.log("Notifications", this.notifications);
    }, 4000);
  },
  computed: {
    ...mapState(["user", "userInfo"]),

    // ...mapGetters({
    //     notifications: 'noti/notifications'
    // }),
    notifications() {
      let ns = this.$store.getters["noti/notifications"];
      // fake data
      // ns = [{id:1,title:"title1",text:"text1",ts:1720961906,unread:true},{id:2,title:"title2",text:"text2",ts:1720961906,unread:true}];
      return Object.values(ns).sort((a, b) => {
        return b.ts - a.ts;
      });
    },
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    add() {
      this.$router.push("/digital-card/add");
    },
    change() {
      this.$router.push("/digital-card/change");
    },
    qrcode() {
      // this.$router.push("/digital-card/qrcode");
    },
    async getDigitalCardNoList() {
      const url = `${this.apiHost}/digital_card/card_no_list/v1/merchants/${this.merchantId}/card_no_list?sso_uid=${this.user.userId}&merchant_id=${this.merchantId}&member_id=${this.userInfo.memberId}`;

      this.digitalList = await axios({
        url,
        method: "GET",
      }).then(function (response) {
        return response.data.data;
      });

      this.currentActive = this.digitalList.filter(function (obj) {
        return obj.active == 1;
      });

      if (this.currentActive.length > 0) {
        this.readerCode = this.currentActive[0].card_no;
        this.popupObj.buttomTitle = "證號 " + this.currentActive[0].card_no;
        this.popupObj.readercode = this.currentActive[0].card_no;
      }
    },
    setPopupObj(val) {
      this.popupObj.open = val;
    },
  },
};
</script>

<style scoped>
.tab {
  border-radius: 0 !important;
}

.record {
  padding: 16px !important;
}

button {
  color: white;
  border-radius: 16px !important;
}

.button-outline {
  color: var(--k1) !important;
  border-radius: 16px !important;
  background-color: #fff;
  border: 1px solid var(--k1);
}
</style>

<style src="@/assets/css/app.css"></style>
<style src="@/assets/css/unique.css"></style>
<style scoped src="@/assets/css/payment.css"></style>
